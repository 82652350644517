import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useState } from "react";
import { getGSheetAppConfig } from "./gsheet_app_config";

const blogSlug = "neptyne-is-shutting-down";

export const NeptyneIsShuttingDownBanner = () => {
  const [isBannerVisible, setIsBannerVisible] = useState(true);
  const [blogPostIsLive, setBlogPostIsLive] = useState(false);
  const { serverUrlBase, inGSMode } = getGSheetAppConfig();

  useEffect(() => {
    const domain = serverUrlBase || "";
    fetch(`${domain}/api/is_the_shutdown_announcement_live?slug=${blogSlug}`)
      .then((response) => response.text())
      .then((text) => text === "yes" && setBlogPostIsLive(true))
      .catch(() => {});
  }, [serverUrlBase]);

  const handleCloseBanner = () => {
    setIsBannerVisible(false);
  };

  return blogPostIsLive && isBannerVisible ? (
    <div
      style={{
        width: "100%",
        height: "100%",
        maxHeight: inGSMode ? "100px" : "40px",
        background: "#fc5d5d",
        padding: "2px",
        flexDirection: "row",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <div style={{ textAlign: "center", display: "flex" }}>
        <b>
          Neptyne is shutting down on March 1, 2025.{" "}
          <a
            href={`https://www.neptyne.com/blog/${blogSlug}`}
            target="_blank"
            rel="noreferrer"
          >
            Click to learn more about transitioning to the open source product.
          </a>
        </b>
      </div>
      <IconButton
        aria-label="close-banner"
        style={{ paddingRight: "10px" }}
        onClick={handleCloseBanner}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </div>
  ) : null;
};
